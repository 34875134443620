import * as React from "react";

import img from "../static/slider_images/4.jpeg";
import { MainLayout } from "../layouts/MainLayout";

// markup
const DesignTechnologyConsultancy = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>
                    DESIGN & <br />
                    TECHNOLOGY CONSULTANCY
                  </h1>
                  {/* <figure className="image is-96x96">
                    <img src={img} alt="Factory" />
                  </figure> */}
                </div>
                <p className="page-description">
                This comprises of design and production of parts, 
                and reproduction of engineering prints, as part of project management and allied activities.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Products</h3>
                  <ul>
                    <li>
                      <p className="list-title">
                        <span>Production Of Parts & Systems</span>
                        These are based on own drawings or drawings supplied by the customer.
                      </p>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Drawings & Reproduction</span>
                        This pertains to reproducing engineering prints and technical drawings, including scaling and measuring.
                      </p>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Hydro-struts Consultancy</span>
                        We offer advisory on design, planning, integration and deployment of hydro-pneumatic suspension systems.
                      </p>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default DesignTechnologyConsultancy;
